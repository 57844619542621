import {getHashCode} from "../hash";

function checkNodeClassName(node: Element, name: string) {
    return node.classList.contains(name);
}

export function findChildWithClassName(node: Element, name: string) {

    if (checkNodeClassName(node, name)) {
        return node;
    }

    for (let i = 0; i < node.children.length; ++i) {
        const child = node.children[i];
        const result = findChildWithClassName(child, name);
        if (result != null) {
            return result;
        }
    }

    return null;
}


export function loadScriptDynamically(url: string): Promise<boolean> {

    // Monaco Adds this, and it confuses scripts that can be AMD-module loaded
    const define = window["define"];
    let defineResolver = window["define_resolver"];
    window["define_offset"] = (window["define_offset"] || 0) + 1;

    if (define) {
        window["define"] = undefined;
        const defineResolved = new Promise((resolve => {
            defineResolver = window["define_resolver"] = resolve;
        }));
        setTimeout(async () => {
            await defineResolved;
            window["define"] = define;
            window["define_offset"] = undefined;
            window["define_resolver"] = undefined;
        })
    }

    const scriptId = "script_" + Math.abs(getHashCode(url));

    const loadDynamicScript = async (callback) => {
        const exists = document.getElementById(scriptId);
        if (!exists) {
            const script = document.createElement('script');
            script.src = url;
            script.id = scriptId;
            script.async = true;

            document.head.appendChild(script);
            script.onload = async () => {
                callback();
            };
        } else callback();
    };

    const promise = new Promise<boolean>(async (resolve, reject) => {
        const fail = setTimeout(() => reject("failed to load " + url), 3000);
        await loadDynamicScript(() => {
            clearTimeout(fail);
            resolve(true);
        })
    });

    setTimeout(async () => {
        try {
            await promise;
        } finally {
            window["define_offset"]--;
            if (window["define_offset"] === 0 && defineResolver) {
                defineResolver();
            }
        }
    });

    return promise
}

