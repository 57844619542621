const alphabet = 'abcdefghjklmnpqrstuvwxyz';

export function makeReandomIdentifier(length: number = 8) {
	let text = '';
	const n = alphabet.length;
	for (let i = 0; i < 8; i++) {
		text += alphabet.charAt(Math.floor(Math.random() * n));
	}
	return text;
}

export function makeUUID() {
	let result, i, j;
	result = '';
	for (j = 0; j < 32; j++) {
		if (j == 8 || j == 12 || j == 16 || j == 20) {
			result = result + '-';
		}
		i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
		result = result + i;
	}
	return result;
}
