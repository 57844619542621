export function clone(any: any) {
    if (any === null) return null;
    return JSON.parse(JSON.stringify(any));
}

export function removeFromArray<T>(array: T[], element: T) {

    if (!array)
        return array;

    const idx = array.indexOf(element);
    if (idx !== -1)
        array.splice(idx, 1);

    return array;
}
