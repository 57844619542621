import {Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BlockBackend, RPCBackend} from '../constants';
import {IBlock, IConfiguration, IConfigurationValue, IGlue} from "@common/blocks";
import {RenderView} from "@common/renderer";
import {IRPCExecutor} from "@common/interfaces";
import {findChildWithClassName} from "@common/webutils/webutils";

interface IServerData extends IConfiguration {
	block: IBlock,
	glue: IGlue
}

interface IPayload<T> {
	data: T | null
	ok: boolean;
	error: string;
}

class ClientRPCExecutor implements IRPCExecutor {

	constructor(private readonly http: HttpClient) {
	}

	private async postAny(backend: string, action: string, payload: any = {}): Promise<any> {

		const signedPayload = {
			'userId': 'NOT SET',
			'action': action,
			...payload
		};

		const result = this.http.post(backend, signedPayload).toPromise();

		return new Promise<any>(async r => {

			let error = null;
			try {
				const response = await result as any;
				if (response.ok) {
					r(response.data);
				} else {
					error = response.error;
				}
			} catch (e) {
				error = e;
			}

			if (error != null) {
				console.error(error);
			}
		});
	}

	async sessionRPC(sessionId: any, fn: string, params: any[]): Promise<any> {
		return await this.postAny(RPCBackend, fn, {
			'sessionId': sessionId,
			'params': params
		});
	}

	rpc(fn: string, params: any[]): Promise<any> {
		return undefined;
	}
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

	loading: boolean = true;
	error: boolean = false;

	private readonly _rv: RenderView = null;

	constructor(
		private readonly elem: ElementRef,
		private readonly zone: NgZone,
		private readonly http: HttpClient) {
		const rpc = new ClientRPCExecutor(http);
		this._rv = new RenderView(zone, rpc);
	}

	async getData(sessionId: any): Promise<IServerData> {

		const data = await (this.http.post(BlockBackend, {
			'action': 'block-session-data',
			'sessionId': sessionId
		}).toPromise()) as IPayload<IServerData>;

		if (!data.ok) {
			console.error(data.error);
			this.error = true;
			this.loading = false;
		}


		data.data.glue = JSON.parse(data.data.glue as any as string || '');



		if (!data.data.settings) {
			data.data.settings = [];
		}

		if (!data.data.inputs) {
			data.data.inputs = [];
		}

		return data.data;
	}

	async ngOnInit() {
		const urlParams = new URLSearchParams(window.location.search);
		var sessionId: any = urlParams.get('session');
		var isRoot = false;
		this.loading = true;
		console.log('## in ##');
		if (sessionId == null) {

			sessionId = 0;
			isRoot = true;
		}else if (sessionId == "null"){
			sessionId = 0;
			isRoot = true;

		}
		const data = await this.getData(sessionId);


		if (isRoot == true){

			var u = window.location.href + "?session=" + data['sessionId'];
			// Simulate an HTTP redirect:
			window.location.replace(u);
		}


		this._rv.setSession(sessionId);
		this._rv.setGlue(data.glue);
		const target = findChildWithClassName(this.elem.nativeElement, 'render-container');
		this._rv.setHost(target, data.block);
		target.innerHTML = await this._rv.render(data.block, data);

		setTimeout(async () => {
			await this._rv.runApplication(data.block, data);
			this.loading = false;
		});


		/*




		if (sessionId) {


			const data = await this.getData(sessionId);


			this._rv.setSession(sessionId);
			this._rv.setGlue(data.glue);
			const target = findChildWithClassName(this.elem.nativeElement, 'render-container');
			this._rv.setHost(target, data.block);
			target.innerHTML = await this._rv.render(data.block, data);

			setTimeout(async () => {
				await this._rv.runApplication(data.block, data);
				this.loading = false;
			});
		} else {
			this.error = true;
			this.loading = false;
		}
		*/


	}

	get rpcInProgress(): boolean {
		return this._rv.rpcInProgress;
	}
}
