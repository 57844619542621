import {Subject} from 'rxjs';

import Vue from 'vue';
import Vuetify from 'vuetify';

import CKEditor from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

let vueIsInitialized = false;
Vue.config.productionTip = false

export const vueErrors = new Subject<string>();

export interface IVueEditorConfig {
	controller: any;
	options: any;
}

export function getVueEditorConfig(): IVueEditorConfig {
	return {
		controller: BalloonEditor,
		options: {}
	};
}

export function instantiateVue(id: string, data: any, methods: any, watch: any, computed: any): Vue {

	if (!vueIsInitialized) {
		vueIsInitialized = true;

		Vue.use(CKEditor);
		Vue.use(Vuetify);

		Vue.config.errorHandler = err => {
			vueErrors.next(err.name + '\n' + err.message);
		};

		Vue.config.warnHandler = err => {
			vueErrors.next(err);
		};
	}

	return new Vue({
		vuetify: new Vuetify(),
		data: data,
		methods: methods,
		watch: watch,
		computed: computed,
	}).$mount("#" + id);
}
