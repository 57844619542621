import {blockTag, IBlock, IBlockNode, IConfiguration, IGlue, InputType, traverse} from './blocks';

import {NgZone} from '@angular/core';
import {Subscription} from 'rxjs';
import * as less from 'less';


import {makeReandomIdentifier} from './dom';

import {getVueEditorConfig, instantiateVue, vueErrors} from './vueutil';
import {getHashCode} from "./hash";
import {IRPCExecutor} from "./interfaces";
import {clone} from "./webutils/general";
import {loadScriptDynamically} from "./webutils/webutils";

const selfClosingTags = {'input': true};

function arr(passed_arguments) {
    const array = Array(passed_arguments.length);
    for (let i = 0; i < passed_arguments.length; ++i) {
        array[i] = passed_arguments[i];
    }
    return array;
}

function encodeAttribute(name: string): string {
    return `${name}`;
}

function makeIndent(depth: number): string {
    let indent = '\n';
    for (let i = 0; i < depth + 1; ++i) {
        indent += '    ';
    }
    return indent;
}

function getTag(block: IBlockNode): [string, boolean] {
    const tag = blockTag(block.htmlType, block);
    return [tag, selfClosingTags.hasOwnProperty(tag)];
}

function validExpression(action: string): boolean {
    return action && action.trim().length > 0;
}

function buildTag(block: IBlockNode, depth: number, id?: string): string {

    let [tag, selfClosing] = getTag(block);
    let attributes: { [tag: string]: string } = {};

    if (block.isContentBlock && tag !== 'input') {
        [tag, selfClosing] = ['ckeditor', false];
        attributes[':editor'] = 'UPWINTERNAL.EditorController';
        attributes[':config'] = 'UPWINTERNAL.EditorConfig';
        attributes['v-model'] = 'NODE(\'' + block.id + '\').contentHtml';
    }

    let html = '<' + tag;

    for (let attributeKey in block.attrs) {
        attributes[attributeKey] = block.attrs[attributeKey];
    }


    if (block.className) {
        attributes['class'] = encodeAttribute(block.className);
    }

    if (id) {
        attributes['id'] = encodeAttribute(id);
    }

    if (validExpression(block.repeatBinding) && validExpression(block.repeatBinding)) {
        attributes['v-for'] = `${block.repeatBinding} in ${block.repeatAction}`;
    }

    if (validExpression(block.enableAction)) {
        attributes['v-if'] = `${block.enableAction}`;
    }

    if (validExpression(block.clickAction)) {
        attributes['v-on:click'] = `${block.clickAction}`;
    }

    if (validExpression(block.classAction)) {
        attributes['v-bind:class'] = block.classAction;
    }

    if (validExpression(block.placeholder)) {
        attributes['placeholder'] = block.placeholder;
    }

    if (validExpression(block.model)) {
        attributes['v-model'] = block.model;
    }

    for (let attribute in attributes) {
        const value = attributes[attribute];
        html += ` ${attribute}="${value}"`;
    }


    if (!selfClosing) {
        html += '>';
        const indent = makeIndent(depth);

        if (block.text && block.text.length > 0) {
            html += indent + block.text;
        }

        for (let child of block.children) {
            html += indent + buildTag(child, depth + 1);
        }

        const ondent = makeIndent(depth - 1);
        html += ondent + '</' + tag + '>';

    } else {
        html += '/>';
    }

    return html;
}


export function buildHtml(root: IBlockNode, id: string): string {
    return `<v-app id=${id}>${buildTag(root, 0)}</v-app>`;
}

export class RenderView {

    private _css: string = null;
    private _cssHash: number = null;

    private _destroyed: boolean = false;
    private _styleElement: Node = null;
    private _rpcInFlightCounter = 0;
    private _vue: any;

    private _sessionId: number | null;
    private _glue: IGlue | null;
    private _targetId: string;

    private _vueErrorSubscription: Subscription;

    constructor(private readonly zone: NgZone,
                private readonly host: IRPCExecutor
    ) {
        this._vueErrorSubscription = vueErrors.subscribe((err) => {
            this.onError('VUE', err);
        });
    }

    get rpcInProgress() {
        return this._rpcInFlightCounter > 0;
    }

    onError(source, error) {
        console.error(source, error);
    }

    async initSession(): Promise<boolean> {
        return false;
    }

    private getSettingsConfigObject(block: IBlock, config: IConfiguration | null): any {

        if (config === null)
            return {};

        if (config.settings instanceof Array) {
            const map = {};

            const srm = {};
            for (let setting of block.settings) {
                srm[setting.id] = setting;
            }

            for (let setting of config.settings) {
                const varname = srm[setting.referenceId].name;
                map[varname] = setting.value
            }
            return map;
        }

        return config.settings;
    }

    private getInputConfigurationObject(block: IBlock, config: IConfiguration | null): any {
        if (config === null)
            return {};

        if (config.inputs instanceof Array) {
            const inputs = {};

            for (let input of config.inputs) {
                const val = block.variables.find(v => v.id === input.referenceId);

                if (val) {
                    if (val.type == InputType.Object) {
                        try {
                            inputs[val.name] = JSON.parse(input.value);
                        } catch (e) {
                            inputs[val.name] = {}
                        }
                    }

                    if (val.type == InputType.Number) {
                        try {
                            inputs[val.name] = parseFloat(input.value);
                        } catch (e) {
                            inputs[val.name] = 0
                        }
                    }

                    if (val.type == InputType.String) {
                        try {
                            inputs[val.name] = input.value
                        } catch (e) {
                            inputs[val.name] = ""
                        }
                    }
                }
            }

            return inputs;
        }

        return config.inputs;
    }

    private generateLess(block: IBlock, config: IConfiguration | null) {

        const lessVariables = [];

        if (config) {
            const settings = this.getSettingsConfigObject(block, config);

            for (let setting in settings) {
                const value = settings[setting] || '';
                const code = '@' + setting + ':' + value;
                lessVariables.push(code);
            }
        }

        const vars = lessVariables.join(';\n');
        const source = vars + ';\n' + block.stylesheet;

        return `.block${block.id} {${source}}`;
    }

    private generateServerStub() {

        const that = this;

        const server = {};
        if (this.haveGlue) {

            for (let fdef of this._glue.fdefs) {
                server[fdef.remote] = function () {

                    const params = arr(arguments);
                    return new Promise(async (resolve, reject) => {
                        try {

                            if (!that.haveSession) {
                                if (!await that.initSession()) {
                                    that.onError('VUE', 'No server');
                                    return;
                                }
                            }

                            if (params.length != fdef.args.length) {
                                that.onError("SERVER", "Invalid use of server function '" + fdef.remote + "()'. Check parameters.");
                                reject('Need params: ' + fdef.args.join(', '));
                                return;
                            }


                            const result = await that.zone.run(async () => {
                                try {
                                    that._rpcInFlightCounter += 1;
                                    return await that.host.sessionRPC(that._sessionId, fdef.remote, params);
                                } finally {
                                    that._rpcInFlightCounter -= 1;
                                }
                            });

                            resolve(result);
                        } catch (e) {
                            reject(e);
                        }
                    });
                };
            }
        }

        return server;
    }

    private initScope(block: IBlock, config: IConfiguration | null = null) {
        const inputs = this.getInputConfigurationObject(block, config);
        const settings = this.getSettingsConfigObject(block, config);
        return [clone(inputs), clone(settings)];
    }

    private async loadExternalLibraries(block: IBlock) {
        const promises: Promise<boolean>[] = [];
        for (let library of block.libraries) {
            promises.push(loadScriptDynamically(library.url));
        }

        try {
            await Promise.all(promises);
        } catch (e) {
            this.onError("SCRIPTS", e);
        }
    }

    private async generateStyles(block: IBlock, config: IConfiguration | null) {
        try {

            const lessCode = this.generateLess(block, config);
            const hash = getHashCode(lessCode);

            if (this._cssHash !== hash) {
                const compilation = await less.render(lessCode);
                this._css = compilation.css;
                this._cssHash = hash;
            }

            const head = document.head || document.getElementsByTagName('head')[0];

            if (this._styleElement) {
                try {
                    head.removeChild(this._styleElement);
                } catch (e) {
                    console.warn("Unable to clear styles from HEAD");
                }
            }

            const id = "renderer_block_" + block.id;
            const existingStyle = document.getElementById(id);
            if (existingStyle) {
                try {
                    existingStyle.parentNode.removeChild(existingStyle);
                } catch (e) {
                    console.warn("Unable to clear styles from", existingStyle.parentNode)
                }
            }

            const style = document.createElement('style');
            style.id = id;
            style.appendChild(document.createTextNode(this._css));
            head.appendChild(style);
            this._styleElement = style;

        } catch (e) {
            this.onError('LESS', e);
        }
    }

    setSession(sessionId: number) {
        this._sessionId = sessionId;
    }

    setGlue(glue: IGlue) {
        this._glue = glue;
    }

    setHost(elem: HTMLElement, block: IBlock) {
        elem.classList.add(`block${block.id}`);
    }

    async render(block: IBlock, config: IConfiguration | null = null) {
        const loadPromises = this.loadExternalLibraries(block);
        await this.generateStyles(block, config);
        await loadPromises;
        this._targetId = makeReandomIdentifier(6);
        return buildHtml(block.root, this._targetId);
    }

    runApplication(block: IBlock, config: IConfiguration | null = null) {

        const start = window.performance.now();

        if (this._vue) {
            this._vue.$destroy();
        }

        const server = this.generateServerStub();
        const [inputs, settings] = this.initScope(block, config);

        if (this._destroyed) {
            return;
        }

        if (!document.getElementById(this._targetId)) {
            return;
        }

        const js = block.js;

        const scope = {
            'inputs': inputs,
            'settings': settings
        };

        let jss = null;
        try {
            jss = Function('scope', 'server',
                js + ';\n' +
                'var dataInternalDef = {};' +
                'try{dataInternalDef=data;}catch(e){}' +
                'var initInternalDef=function(){};' +
                'try{initInternalDef=init;}catch(e){}' +
                'var methodsInternalDef = {};' +
                'try{methodsInternalDef=methods;}catch(e){}' +
                'var watchInternalDef = {};' +
                'try{watchInternalDef=watch;}catch(e){}' +
                'var computedInternalDef = {};' +
                'try{computedInternalDef=computed;}catch(e){}' +
                'return {\'computed\':computedInternalDef,\'watch\':watchInternalDef,\'methods\':methodsInternalDef, \'data\':dataInternalDef, \'init\':initInternalDef}')(scope, server);
        } catch (e) {
            this.onError('JAVASCRIPT', e);
        }

        if (jss == null) {
            return;
        }

        const methods = jss['methods'];
        const data = jss['data'];
        const init = jss['init'];
        const watch = jss["watch"];
        const computed = jss["computed"];

        let initOk = true;
        try {
            const result = init();

            if (result && result.then) {
                result.catch((e) => {
                    this.onError("JAVASCRIPT", e);
                })
            }
        } catch (e) {
            this.onError("JAVASCRIPT", e);
            initOk = false;
        }

        if (!initOk)
            return;

        const vueEditorConfig = getVueEditorConfig();

        data['UPWINTERNAL'] = {
            'EditorController': vueEditorConfig.controller,
            'EditorConfig': vueEditorConfig.options
        };

        data["scope"] = scope;

        const nodeMap = traverse(block.root);
        methods['NODE'] = function (nodeId) {
            return nodeMap[nodeId];
        };

        this._vue = instantiateVue(this._targetId, data, methods, watch, computed);

        const time = window.performance.now() - start;
        if (time > 200)
            console.warn(`App Init Took [${time}ms]`)
    }

    destroy() {
        this._destroyed = true;
        this._vueErrorSubscription.unsubscribe();
    }

    get haveSession() {
        return this._sessionId;
    }

    get haveGlue() {
        return this._glue;
    }

    clearSession() {
        this._sessionId = null;
    }
}
